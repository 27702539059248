import React from "react"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

// アーカイブページ、終了タググレーアウト処理
export function archiveGrayOut (posts, leadUri, postLang) {
  
  // アーカイブリスト
  let archiveData = posts.map(post => {
    let title, uri, tagDataArray, finTagClass, finTagClassData;
    title = post.node.title ; 
    uri = leadUri + post.node.slug + '/' ;
    tagDataArray = post.node.campaignTag.nodes;
    finTagClassData = 'camEndFlag'; // 終了タグが付いている記事のグレーアウトCSS
    let finTagArray = [];
    for(let i = 0; i < tagDataArray.length ; i++){
      if ( tagDataArray[i].name === '終了' ) {
        finTagArray.push(finTagClassData);
      } 
    }
    (finTagArray[0] === finTagClassData) ? finTagClass = finTagArray[0] : finTagClass = '';
    
    return (
    <li className="md:w-[30%] mx-[1.6%]" key={post.node.uri} style={{ marginBottom:30}}>
      <div className="post-list-item !m-0 h-[100%]">
        <a href={uri} itemProp="url" className={"Campaign w-[100%] " + finTagClass}>
          <div className="w-full rounded" style={{ height: '80%' }}> {/* 画像部分 */}
            <GatsbyImage className="block w-full h-full rounded" 
              image={post.node.featuredImage ? post.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData : ""} 
              alt={post.node.featuredImage ? post.node.featuredImage.node.altText : ""} 
              as={`figure`} style={{ aspectRatio: "25/18.5" , height: '100%' }} /> </div> {/* タイトル部分 */}
            <div className="w-full ">
            <p className={"CampP text-xs font-bold mb-4 bg-gray-100 px-4 pt-3 pb-3 h-[100%] min-h-[60px]"}>{parse(title)}</p>
            {/* 要調整 */}
            <p className={"CampP text-[10px] pb-5 mb-4 bg-gray-100 md:mb-0 px-4 flex hidden"} >
              <span className="pr-5">{postLang["common-txt2"]}{post.node.date}</span>
              <span className="">{postLang["common-txt3"]}{post.node.modified}</span>
            </p>
          </div>
        </a>
      </div>
    </li> 
    )
  })
  return archiveData;
}